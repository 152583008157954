<template>
  <modal-brandme :show_modal="show" :size="'lg'">
    <template #title>{{ $t('creator.filters') }}</template>
    <template #body>
      <b-row class="px-1">
        <b-col class="col-12 d-flex flex-wrap">
          <strong class="text-muted d-block mb-1 w-100">{{ $t('creator.followersCount') }}</strong>
          <vue-slider
            class="col-12 mb-2"
            v-model="followers_range"
            :direction="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :max="100000000"
            :min="10"
            :interval="1"
            :tooltip-formatter="formatter_commas"
          ></vue-slider>
          <b-form-group :label="$t('creator.followersMin')" label-for="input-followers-min" class="col-6 p-0 pr-1">
            <cleave
              :options="options_cleave.number"
              v-model="followers_range[0]"
              class="form-control" 
              maxlength="11"
            />
          </b-form-group>
          <b-form-group :label="$t('creator.followersMax')" label-for="input-followers-max" class="col-6 p-0 pl-1">
            <cleave
              :options="options_cleave.number"
              v-model="followers_range[1]"
              class="form-control" 
              maxlength="11"
            />
          </b-form-group>
        </b-col>

        <b-col class="col-12 d-flex flex-wrap">
          <strong class="text-muted d-block mb-1 w-100">{{ $t('creator.priceRange') }}</strong>
          <vue-slider
            class="col-12 mb-2"
            v-model="price_range"
            :direction="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :max="100000"
            :min="0"
            :interval="1"
            :tooltip-formatter="formatter_commas"
          ></vue-slider>
          <b-form-group :label="$t('creator.priceMin')" label-for="input-price-min" class="col-6 p-0 pr-1">
            <b-input-group prepend="$" id="input-price-min">
              <cleave
                :options="options_cleave.number"
                v-model="price_range[0]"
                class="form-control" 
                maxlength="7"
              />
            </b-input-group>
          </b-form-group>
          <b-form-group :label="$t('creator.priceMax')" label-for="input-price-max" class="col-6 p-0 pl-1">
            <b-input-group prepend="$" id="input-price-max">
              <cleave
                :options="options_cleave.number"
                v-model="price_range[1]"
                class="form-control" 
                maxlength="7"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col class="col-12">
          <strong class="text-muted d-block mb-1">{{$t('creator.workTypes')}}</strong>
          <v-select
            :filter="search"
            :placeholder="$t('creator.searchWork')"
            v-model="work_types"
            multiple
            :options="$t('creator.type_of_work')"
            class="mb-2 select-modal-filters"
            label="text"
            :close-on-select="false"
          >
            <template #selected-option="{text}">
              <span>{{text}}</span>
            </template>
            <template slot="option" slot-scope="option">
              {{option.text}}
            </template>
          </v-select>
        </b-col>
        <b-col class="col-12">
          <strong class="text-muted d-block mb-1">{{$t('creator.contentTypes')}}</strong>
          <v-select
            :filter="search"
            :placeholder="$t('creator.searchContentType')"
            v-model="content_types"
            multiple
            :options="getContentTypes"
            class="mb-2"
            label="text"
            :close-on-select="false"
          >
            <template #selected-option="{text}">
              <span>{{text}}</span>
            </template>
            <template slot="option" slot-scope="option">
              {{option.text}}
            </template>
          </v-select>
        </b-col>
        <b-col class="col-12">
          <strong class="text-muted d-block mb-1">{{$t('creator.countries')}}</strong>
          <v-select
            :filter="search"
            :placeholder= "$t('creator.searchCountry')"
            v-model="countries"
            multiple
            :options="options_countries"
            class="mb-2"
            label="text"
            :close-on-select="false"
          >
            <template #selected-option="{text}">
              <span>{{text}}</span>
            </template>
            <template slot="option" slot-scope="option">
              {{option.text}}
            </template>
          </v-select>
        </b-col>
      </b-row>
      <b-col class="col-0 p-0 d-flex justify-content-end">
        <b-button variant="outline-secondary" @click="clearFilters">{{ $t('creator.clearFilter') }}</b-button>
        <b-button class="float-right ml-1" variant="primary" @click="sendFilters()">{{ $t('creator.filterButton') }}</b-button>
      </b-col>
    </template>   
  </modal-brandme>
</template>

<script>
import {
  BButton,
  BCol,
  BRow,
  BFormGroup,
  BInputGroup,
} from 'bootstrap-vue';
import VueSlider from 'vue-slider-component';
import vSelect from "vue-select";
import cleave from 'vue-cleave-component';
import { separatebycomma } from '@/libs/utils/formats'

export default {
  name: 'filterSearchProposal',
  components: {
    cleave,
    BButton,
    BCol,
    BRow,
    BInputGroup,
    BFormGroup,
    VueSlider,
    vSelect,
    modalBrandme: () => import('@/views/components/modal/ModalBrandme.vue'),
  },
  props: {
    open_modal: {
      type: Boolean,
      default: false,
    },
    update_key: {
      type: Boolean,
    },
    filters_layout: {
      type: Array,
    },
    countries_json: {
      type: Array,
      default: () => [],
    }
  },
  data() {
    return {
      options_cleave: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
        },
      },
      show: this.open_modal,
      followers_range: ['10', '100000000'],
      price_range: ['0', '100000'],
      countries: [],
      preferred_countries: ['MX', 'AR', 'BR', 'CO', 'CR', 'CL', 'EC', 'PE', 'UY', 'US'],
      options_countries: [],
      formatter_commas: v => `${separatebycomma(v)}`,
      work_types: [],
      content_types: [],
      filters: [
        {
          filter: 'followers_range',
          text: 'followers_range',
          models: [{name: 'followers_range', value: null, default: ['10', '100000000']}],
        },
        {
          filter: 'price_range',
          text: 'price_range',
          models: [{name: 'price_range', value: null, default: ['0', '100000']}],
        },
        {
          filter: 'work_types',
          text: 'work_types',
          models: [{name: 'work_types', value: null, default: []},],
        },
        {
          filter: 'content_types',
          text: 'content_types',
          models: [{name: 'content_types', value: null, default: []},],
        },
        {
          filter: 'countries',
          text: 'countries',
          models: [{name: 'countries', value: null, default: []},],
        },
      ],
    }
  },
  computed: {
    getContentTypes() {
      const content_types = [];
      this.$t('creator.type_of_work').map((work_type) => {
        this.$t(`creator.focus_in_${work_type.value}`).map((content_type) => {
          content_types.push(content_type);
        });
      });
      return content_types;
    }
  },
  created() {
    this.getCountriesName();
    if (this.filters_layout.length > 0) {
      this.filters = this.filters_layout;
      this.filters.map((fil) => {
        const model = fil.models.find((model) => model.name === fil.filter);
        if (model.value !== null) this[fil.filter] = model.value;
      });
    }

    if ((Object.keys(this.$route.query).length > 0 && this.work_types.length < 2) && this.$route.query.work_types) {
      const work_type_find = this.$t('creator.type_of_work').find((work_type) => work_type.value === this.$route.query.work_types);
      this.filters[2].models[0].value = [work_type_find];
      this.work_types = [work_type_find];
    }
    const contents = this.getContentTypes;
    if ((Object.keys(this.$route.query).length > 0 && this.content_types.length < 2) && this.$route.query.content_type) {
      const content_types_find = contents.find((content_type) => content_type.value === this.$route.query.content_type);
      this.filters[3].models[0].value = [content_types_find];
      this.content_types = [content_types_find];
    }
  },
  
  methods: {
    clearFilters() {
      this.$router.push({name: 'search_proposal'});
      this.filters.map((fil) => {
        fil.models.forEach((item) => {
          this[item.name] = item.default;
          item.value = null;
        });
      });
      this.$emit('filter', this.getFilters(), this.checkFiltersUsed().length, []);
    },
    changeFilter(name_filter, new_models) {
      const filter = this.filters.find(item => item.filter === name_filter)
      filter.models.forEach(model => {
        const new_model = new_models.find(mod => mod.name === model.name)
        if (new_model) {
          model.value = new_model.value
        }
      })
    },
    getCountriesName() {
      this.countries_json.map((item) => {
        if (this.preferred_countries.find((preferred) => preferred === item['alpha-2']) !== undefined) {
          this.options_countries.unshift({value: item['alpha-2'], text: item.name})
        } else {
          this.options_countries.push({value: item['alpha-2'], text: item.name})
        }
      });
    },
    search(options, search) {
      const results = options.filter((option) => {
        const translate = option.text.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        if (translate.indexOf(search.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) !== -1) {
          return option;
        }
      });
      return results;
    },
    getFilters() {
      return {
        work_types: this.work_types.map((item) => item.value),
        content_types: this.content_types.map((item) => item.value),
        countries: this.countries.map((item) => item.value),
        followers_min: this.followers_range[0] !== '10' ? this.followers_range[0] : undefined,
        followers_max: this.followers_range[1] !== '100000000' ? this.followers_range[1] : undefined,
        price_min: this.price_range[0] !== '0' ? this.price_range[0] : undefined,
        price_max:this.price_range[1] !== '100000' ? this.price_range[1] : undefined,
      }
    },
    checkFiltersUsed() {
      return this.filters.filter((item) => item.models[0].value !== null);
    },
    sendFilters() {
      this.$emit('filter', this.getFilters(), this.checkFiltersUsed().length, this.filters);
    },
  },
  watch: {
    followers_range(val) {
      if (JSON.stringify(val) !== JSON.stringify(['10', '100000000'])) {
        const new_models = [{name: 'followers_range', value: val}]
        this.changeFilter('followers_range', new_models);
      } else {
        this.changeFilter('followers_range', [{name: 'followers_range', value: null}]);
      }
    },
    price_range(val) {
      if (JSON.stringify(val) !== JSON.stringify(['0', '100000'])) {
        const new_models = [{name: 'price_range', value: val}]
        this.changeFilter('price_range', new_models);
      } else {
        this.changeFilter('price_range', [{name: 'price_range', value: null}]);
      }
    },
    work_types(val) {
      if (val.length > 0) {
        const new_models = [{name: 'work_types', value: val}]
        this.changeFilter('work_types', new_models);
      }  else {
        this.changeFilter('work_types', [{name: 'work_types', value: null}]);
      }
    },
    content_types(val) {
      if (val.length > 0) {
        const new_models = [{name: 'content_types', value: val}]
        this.changeFilter('content_types', new_models);
      }  else {
        this.changeFilter('content_types', [{name: 'content_types', value: null}]);
      }
    },
    countries(val) {
      if (val.length > 0) {
        const new_models = [{name: 'countries', value: val}]
        this.changeFilter('countries', new_models);
      } else {
        this.changeFilter('countries', [{name: 'countries', value: null}]);
      }
    },
  }
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-slider.scss';
</style>